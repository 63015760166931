import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Group, Col, Section, CtaButton } from "../components/common"

import { articles, negativeArticles } from "../data"

class NewsPage extends Component {
  renderNewsItem(article) {
    const typeClassName = article.positive ? "positive" : "negative"
    const className = `newsItem ${typeClassName}`

    return (
      <Col size="third">
        <div
          className={className}
          style={{ backgroundImage: `url(${article.image})` }}
        >
          <h4>{article.title}</h4>
          <p>{article.subtitle}</p>
          <a href={article.url} target="_blank" className="cta-link">
            <div className="chevron-circle" />
          </a>
        </div>
      </Col>
    )
  }
  renderNewsItems() {
    const mixedArticles = [
      ...articles.map(a => ({ ...a, positive: true })),
      ...negativeArticles.map(a => ({ ...a, positive: false })),
    ].sort(() => {
      return 0.5 - Math.random()
    })

    return mixedArticles.map(article => {
      return this.renderNewsItem(article)
    })
  }

  render() {
    return (
      <>
        <Layout>
          <SEO title="Home" keywords={[]} />
          <Section dark={true} hasDivider={false}>
            <div style={{ height: 100 }} />
            <Group>
              <Col size="whole">
                <div className="headingAccent" />
                <div style={{ height: 32 }} />
                <h3 className="headerBigText">News</h3>
              </Col>
            </Group>
            <div style={{ height: 80 }} />
          </Section>

          <Section hasDivider={false}>
            <Group>{this.renderNewsItems()}</Group>
          </Section>
        </Layout>
      </>
    )
  }
}

export default NewsPage
